import React, {Component} from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ISubscriptionPlan, SubscriptionPlan} from './SubscriptionPlan/SubscriptionPlan';
import s from './SubscriptionPlans.scss';
import {UserInputType} from '../../../constants';
import {get} from 'lodash';
import {ErrorTooltipProvider} from '../../ErrorTooltipProvider/ErrorTooltipProvider';
import {hasDiscount} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import {ErrorSmall} from '@wix/wix-ui-icons-common/on-stage';

export const enum SubscriptionPlansDataHooks {
  TITLE = 'plans-title',
  Container = 'subscription-plans-container',
  ErrorMessageContainer = 'error-message-container',
  ErrorMessageText = 'error-message-text',
}

export interface ISubscriptionPlansState {
  plans: ISubscriptionPlan[];
}

export interface ISubscriptionPlansProps extends ProvidedGlobalProps, IProvidedTranslationProps {}

class SubscriptionPlansComponent extends Component<ISubscriptionPlansProps, ISubscriptionPlansState> {
  private onItemClicked(item: ISubscriptionPlan) {
    this.handleUserInput(item);
  }

  public handleUserInput = (input: ISubscriptionPlan): void => {
    const {handleUserInput} = this.props.globals;
    handleUserInput && handleUserInput(UserInputType.SubscriptionPlan, input, 0);
    this.props.globals.validate();
  };

  private isSelected(item: ISubscriptionPlan) {
    const subscriptionPlan = get(this.props.globals.userInputs.subscriptionPlan, ['0']);

    if (!subscriptionPlan) {
      return false;
    }

    if (subscriptionPlan.isOneTimePurchase && item.isOneTimePurchase) {
      return true;
    }

    return subscriptionPlan.id === item.id;
  }

  private readonly getErrorMessage = () => {
    const {t} = this.props;
    return (
      <div className={s.errorMessageContainer} data-hook={SubscriptionPlansDataHooks.ErrorMessageContainer}>
        <ErrorSmall className={s.errorIcon} aria-hidden />
        <span data-hook={SubscriptionPlansDataHooks.ErrorMessageText}>
          {t('PRODUCT_PAGE_PLAN_MISSING_ERROR_TOOLTIP')}
        </span>
      </div>
    );
  };

  public render(): JSX.Element {
    const {
      t,
      globals: {
        product,
        subscriptionPlans,
        selectedVariant,
        errorPlacement,
        isProductSubmitted,
        userInputErrors,
        experiments,
      },
    } = this.props;
    const variantActualPrice = hasDiscount(product)
      ? selectedVariant?.formattedComparePrice
      : selectedVariant?.formattedPrice;

    const isProductPageOptionsMigrateToUITPAEnabled = experiments.productPageOptionsMigrateToUITPA;
    const hasError =
      isProductPageOptionsMigrateToUITPAEnabled && isProductSubmitted && userInputErrors.subscriptionPlan[0];

    if (!isProductPageOptionsMigrateToUITPAEnabled) {
      return (
        <div data-hook={SubscriptionPlansDataHooks.Container}>
          <ErrorTooltipProvider
            className={s.errorTooltip}
            content={t('PRODUCT_PAGE_PLAN_MISSING_ERROR_TOOLTIP')}
            placement={errorPlacement}
            show={isProductSubmitted && userInputErrors.subscriptionPlan[0]}>
            <div className={s.title} data-hook={SubscriptionPlansDataHooks.TITLE}>
              {t('PRODUCT_PAGE_PURCHASE_OPTIONS_TITLE')}
            </div>
          </ErrorTooltipProvider>
          {subscriptionPlans.map((item, index) => {
            return (
              <SubscriptionPlan
                key={index}
                className={s.planItem}
                plan={item}
                planVariantFormattedPrice={
                  selectedVariant?.subscriptionPlans?.[item.id]?.formattedPrice || variantActualPrice
                }
                isSelected={this.isSelected(item)}
                onClick={
                  /* istanbul ignore next */ () => {
                    this.onItemClicked(item);
                  }
                }
              />
            );
          })}
        </div>
      );
    } else {
      return (
        <div data-hook={SubscriptionPlansDataHooks.Container}>
          <div className={s.title} data-hook={SubscriptionPlansDataHooks.TITLE}>
            {t('PRODUCT_PAGE_PURCHASE_OPTIONS_TITLE')}
          </div>
          {subscriptionPlans.map((item, index) => {
            return (
              <SubscriptionPlan
                key={index}
                className={s.planItem}
                plan={item}
                planVariantFormattedPrice={
                  selectedVariant?.subscriptionPlans?.[item.id]?.formattedPrice || variantActualPrice
                }
                isSelected={this.isSelected(item)}
                onClick={() => {
                  this.onItemClicked(item);
                }}
                hasError={hasError}
              />
            );
          })}
          {hasError && this.getErrorMessage()}
        </div>
      );
    }
  }
}

export const SubscriptionPlans = withGlobalProps(withTranslations('globals.texts')(SubscriptionPlansComponent));
