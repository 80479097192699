import React from 'react';
import {Dropdown, DropdownOptionProps} from 'wix-ui-tpa';
// eslint-disable-next-line import/no-deprecated
import {classes} from './OptionsDropdown.st.css';
import s from './OptionsDropdown.scss';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {UserInputType} from '../../../constants';
import {ErrorTooltipProvider} from '../../ErrorTooltipProvider/ErrorTooltipProvider';
import classNames from 'classnames';

export interface OptionsDropdownProps {
  optionIndex: number;
}

export enum DataHook {
  OptionsDropdown = 'options-dropdown',
  OldOptionsDropdown = 'old-options-dropdown',
  OptionsDropdownTitle = 'options-dropdown-title',
}

class OptionsDropdownComponent extends React.Component<
  OptionsDropdownProps & ProvidedGlobalProps & IProvidedTranslationProps
> {
  private readonly handleChange = ({id: selectionId}: DropdownOptionProps) => {
    const {
      optionIndex,
      globals: {handleUserInput, validate},
    } = this.props;

    const nextSelection = this.productOption.selections.find(({id}) => id.toString() === selectionId);

    handleUserInput(UserInputType.Selection, nextSelection, optionIndex);

    validate();
  };

  private readonly isSelectionAvailable = (isSelectable: boolean, isVisible: boolean): boolean => {
    const {
      globals: {experiments, isBackInStockEnabled},
    } = this.props;

    if (experiments.activeDisabledVariants) {
      if (!isVisible) {
        return true;
      }

      /* istanbul ignore next: todo: test */
      return isBackInStockEnabled ? !isSelectable : false;
    } else {
      return isBackInStockEnabled ? !isSelectable : false;
    }
  };

  private get activeSelection() {
    const {
      optionIndex,
      globals: {userInputs, experiments},
    } = this.props;
    if (experiments.activeDisabledVariants) {
      const activeSelection = userInputs[UserInputType.Selection][optionIndex];
      return activeSelection || {id: -1};
    } else {
      return userInputs[UserInputType.Selection][optionIndex];
    }
  }

  private get productOption() {
    const {
      optionIndex,
      globals: {product},
    } = this.props;

    return product.options[optionIndex];
  }

  private get dropdownOptions(): DropdownOptionProps[] {
    const {
      globals: {variantInfo, isBackInStockEnabled, experiments},
    } = this.props;

    const getSelectionAvailabilityInfo = (selectionId: number) =>
      variantInfo.selectionsAvailability[this.productOption.id][selectionId];

    return this.productOption.selections
      .filter((selection) =>
        experiments.activeDisabledVariants ? true : getSelectionAvailabilityInfo(selection.id).isVisible
      )
      .map((selection) => ({
        id: selection.id.toString(),
        isSelectable: isBackInStockEnabled ? true : getSelectionAvailabilityInfo(selection.id).isSelectable,
        value: selection.description,
        unavailable: this.isSelectionAvailable(
          getSelectionAvailabilityInfo(selection.id).isSelectable,
          getSelectionAvailabilityInfo(selection.id).isVisible
        ),
      }));
  }

  public state: Readonly<{labelForId: string}> = {
    labelForId: undefined,
  };

  public render(): JSX.Element {
    const {
      t,
      optionIndex,
      globals: {errorPlacement, isProductSubmitted, userInputErrors, experiments},
    } = this.props;

    const {title, selections, id: dropdownId} = this.productOption;
    const selectionIds = selections.map((selection) => selection.id).join('_');
    const labelId = `product-dropdown-${selectionIds}`;
    const isProductPageOptionsMigrateToUITPAEnabled = experiments.productPageOptionsMigrateToUITPA;

    return (
      <div>
        {!experiments.useDropdownTpaLabel && (
          <div data-hook={DataHook.OptionsDropdownTitle} className={s.title}>
            <label id={labelId} htmlFor={dropdownId}>
              {title}
            </label>
          </div>
        )}

        {!isProductPageOptionsMigrateToUITPAEnabled ? (
          <ErrorTooltipProvider
            className={s.dropDownErrorTooltip}
            content={t('SELECT_OPTION_WARNING', {optionTitle: title})}
            placement={errorPlacement}
            show={isProductSubmitted && userInputErrors[UserInputType.Selection][optionIndex]}>
            <Dropdown
              className={classNames(classes.oldRoot)}
              dropdownId={dropdownId}
              label={/* istanbul ignore next */ experiments.useDropdownTpaLabel ? title : ''}
              aria-label={/* istanbul ignore next */ experiments.useDropdownTpaLabel ? title : ''}
              options={this.dropdownOptions}
              onChange={this.handleChange}
              placeholder={t('SELECT_PLACE_HOLDER')}
              initialSelectedId={(this.activeSelection?.id || '').toString()}
              data-hook={DataHook.OldOptionsDropdown}
              mobileNativeSelect={!experiments.disableMobileNativeDropdown}
            />
          </ErrorTooltipProvider>
        ) : (
          <Dropdown
            className={classNames(classes.root, s.root)}
            dropdownId={dropdownId}
            label={experiments.useDropdownTpaLabel ? title : ''}
            aria-label={experiments.useDropdownTpaLabel ? title : ''}
            options={this.dropdownOptions}
            onChange={this.handleChange}
            placeholder={t('SELECT_PLACE_HOLDER')}
            initialSelectedId={(this.activeSelection?.id || '').toString()}
            data-hook={DataHook.OptionsDropdown}
            mobileNativeSelect={!experiments.disableMobileNativeDropdown}
            newErrorMessage
            error={isProductSubmitted && userInputErrors[UserInputType.Selection][optionIndex]}
            errorMessage={t('SELECT_OPTION_WARNING', {optionTitle: title})}
          />
        )}
      </div>
    );
  }
}

export const OptionsDropdown: React.FC<OptionsDropdownProps & ProvidedGlobalProps & IProvidedTranslationProps> =
  withGlobalProps(withTranslations('globals.texts')(OptionsDropdownComponent));
