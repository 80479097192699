import React, {FC, useState} from 'react';
import {classes as stylableClasses, st} from './ProductCustomText.st.css';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {TextArea} from 'wix-ui-tpa/cssVars';
import {UserInputType} from '../../../constants';

export enum ProductCustomTextDataHooks {
  root = 'product-custom-text',
}
export interface ProductCustomTextProps extends IProvidedTranslationProps, ProvidedGlobalProps {
  handleOnChange(textAreaElemValue: string): void;
  isRequired: boolean;
  maxLength: number;
  title: string;
  errorIndex: number;
}

const ProductCustomTextComponent: FC<ProductCustomTextProps & IProvidedTranslationProps> = ({
  handleOnChange,
  isRequired,
  maxLength,
  title,
  errorIndex,
  t,
  globals: {isProductSubmitted, userInputErrors},
}) => {
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const textAreaClasses = st(stylableClasses.customTextRoot, {
    midHeight: isFocused && maxLength > 25 && maxLength <= 250,
    fullHeight: isFocused && maxLength > 250,
  });

  const label = isRequired ? title : `${title} (${t('OPTIONAL')})`;

  return (
    <TextArea
      data-hook={ProductCustomTextDataHooks.root}
      className={textAreaClasses}
      label={label}
      ariaLabel={label}
      onChange={(event) => setValue(event.target.value)}
      onFocus={() => setIsFocused(true)}
      onBlur={(event) => handleOnChange(event.target.value)}
      value={value}
      maxLength={maxLength}
      required={isRequired}
      showCharCount
      newErrorMessage
      error={isProductSubmitted && userInputErrors[UserInputType.Text][errorIndex]}
      errorMessage={t('REQUIRED_FIELD')}
    />
  );
};

export const ProductCustomText: FC<ProductCustomTextProps> = withGlobalProps(
  withTranslations('globals.texts')(ProductCustomTextComponent)
);
