import React, {ComponentProps} from 'react';
import s from './ProductColor.scss';
import {UserInputType} from '../../../constants';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ErrorTooltipProvider} from '../../ErrorTooltipProvider/ErrorTooltipProvider';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {ColorPicker} from 'wix-ui-tpa';
import {classes as colorOptionStyles} from './ColorOption.st.css';
import {VIEW_MORE_OPTIONS_URL_FRAGMENT} from '@wix/wixstores-client-storefront-sdk/dist/src/constants';
import classNames from 'classnames';

export interface ProductColorsProps {
  optionIndex: number;
  colorPickerCounter: number;
}

export enum DataHook {
  ColorOption = 'product-options-color',
  ColorOptionItem = 'product-options-color-item',
  ColorOptionSectionTitle = 'product-colors-title-section',
  ColorOptionTitle = 'product-colors-title',
}

class ProductColorsComponent extends React.Component<
  ProductColorsProps & ProvidedGlobalProps & IProvidedTranslationProps
> {
  public static defaultProps = {
    allowMultiple: false,
    selected: [],
  };

  private readonly handleChange: ComponentProps<typeof ColorPicker>['onChange'] = (event, itemId) => {
    const {
      optionIndex,
      globals: {handleUserInput, validate, userInputs},
    } = this.props;

    const nextSelection = this.productOption.selections.find((selection) => selection.description === itemId);

    const isDeselected =
      nextSelection && userInputs[UserInputType.Selection].some((selection) => selection?.id === nextSelection.id);

    handleUserInput(UserInputType.Selection, isDeselected ? undefined : nextSelection, optionIndex);

    validate();
  };

  private get activeSelection() {
    const {
      optionIndex,
      globals: {userInputs},
    } = this.props;

    return userInputs[UserInputType.Selection][optionIndex] ?? undefined;
  }

  private get productOption() {
    const {
      optionIndex,
      globals: {product},
    } = this.props;

    return product.options[optionIndex];
  }

  private get colorPickerOptions(): JSX.Element[] {
    const {
      globals: {variantInfo, isBackInStockEnabled, experiments},
    } = this.props;

    const getSelectionAvailabilityInfo = (selectionId: number) =>
      variantInfo.selectionsAvailability[this.productOption.id][selectionId];

    return this.productOption.selections
      .filter((selection) =>
        experiments.activeDisabledVariants ? true : getSelectionAvailabilityInfo(selection.id).isVisible
      )
      .map((selection) => {
        const isDisabled = !getSelectionAvailabilityInfo(selection.id).isSelectable;
        const isVisible = getSelectionAvailabilityInfo(selection.id).isVisible;
        return (
          <ColorPicker.Item
            key={selection.id}
            id={selection.description}
            aria-label={selection.description}
            value={selection.value}
            disabled={isBackInStockEnabled ? false : isDisabled}
            isCrossedOut={isDisabled || (!isVisible && experiments.activeDisabledVariants)}
            tooltip={selection.description}
            checked={(this.activeSelection?.id ? [this.activeSelection.id] : []).includes(selection.id)}
            data-hook={DataHook.ColorOptionItem}
          />
        );
      });
  }

  public readonly renderDescription = (): string => {
    if (!this.activeSelection) {
      return null;
    }

    return `: ${this.activeSelection.description}`;
  };

  private renderColorPicker() {
    const {
      optionIndex,
      globals: {isProductSubmitted, userInputErrors, isQuickView, experiments},
      t,
    } = this.props;

    const isProductPageOptionsMigrateToUITPAEnabled = experiments.productPageOptionsMigrateToUITPA;
    const hasError =
      isProductPageOptionsMigrateToUITPAEnabled &&
      isProductSubmitted &&
      userInputErrors[UserInputType.Selection][optionIndex];
    const errorMessage = hasError ? t('SELECT_OPTION_WARNING', {optionTitle: this.productOption.title}) : undefined;

    return (
      <ColorPicker
        className={classNames(colorOptionStyles.colorOption, {[s.productOptionsColorContainer]: isQuickView})}
        onChange={this.handleChange}
        data-hook={DataHook.ColorOption}
        errorMessage={errorMessage}>
        {this.colorPickerOptions}
      </ColorPicker>
    );
  }

  public render(): JSX.Element {
    const {
      optionIndex,
      colorPickerCounter,
      globals: {isProductSubmitted, userInputErrors, errorPlacement, experiments},
      t,
    } = this.props;

    const selectionIds = this.productOption.selections.map((selection) => selection.id).join('_');
    const titleId = `product-colors-title-${selectionIds}`;
    const isProductPageOptionsMigrateToUITPAEnabled = experiments.productPageOptionsMigrateToUITPA;

    return (
      <section
        id={`${VIEW_MORE_OPTIONS_URL_FRAGMENT}-${colorPickerCounter}`}
        data-hook={DataHook.ColorOptionSectionTitle}
        aria-labelledby={titleId}
        className={s.uiTpaColoPicker}>
        <div id={titleId} data-hook={DataHook.ColorOptionTitle} className={s.description}>
          {this.productOption.title}
          {this.renderDescription()}
        </div>
        {!isProductPageOptionsMigrateToUITPAEnabled ? (
          <ErrorTooltipProvider
            appendTo="scrollParent"
            content={t('SELECT_OPTION_WARNING', {optionTitle: this.productOption.title})}
            placement={errorPlacement}
            show={isProductSubmitted && userInputErrors[UserInputType.Selection][optionIndex]}>
            <div className={s.actionable}>{this.renderColorPicker()}</div>
          </ErrorTooltipProvider>
        ) : (
          <div className={s.actionable}>{this.renderColorPicker()}</div>
        )}
      </section>
    );
  }
}

export const ProductColors: React.FC<ProductColorsProps & ProvidedGlobalProps & IProvidedTranslationProps> =
  withGlobalProps(withTranslations('globals.texts')(ProductColorsComponent));
